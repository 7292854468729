// 事件处理文案map
export const ConfirmTypeMap = new Map<EventConfirmType, string>([
  ['DEBUGGING', '设备调试'],
  ['BREAKDOWN', '真实故障'],
  ['MISINFORMATION', '误报'],
  ['OTHER_REASON', '其他'],
  ['WORK_ORDER', '创建工单'],
])

// 事件类型翻译
export const EventTypeMap = new Map<PlatformEventType, string>([
  ['FAULT', '故障'],
  ['INFO', '信息'],
  ['WARN', '告警'],
])

// 事件类型tag颜色
export const EventTypeColorMap = new Map<PlatformEventType, string>([
  ['FAULT', 'error'],
  ['INFO', 'success'],
  ['WARN', 'warning'],
])

// Map转换为Select组件的options
export const mapToSelectOptions = (map: Map<any, any>) => Array.from(map, ([key, value]) => ({ label: value, value: key }))
